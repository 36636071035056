import React, {useState} from "react"
import logo from './logo.svg';
import './App.css'

import ReactDOM from 'react-dom';
import Modal from 'react-modal';

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
};


function App() {

   let subtitle;

   const [modalIsOpen, setIsOpen] = useState(false);

  function openModal() {
    setIsOpen(true);
  }

  function afterOpenModal() {
    // references are now sync'd and can be accessed.
    subtitle.style.color = '#f00';
  }

  function closeModal() {
    setIsOpen(false);
  }

  const links = [{
    name:"美区 Apple Store 10 美元 礼品卡",
    text:"兑换后充值到余额",
    link:"https://37apps.net/shop/1006"
  },{
    name:"美区 Apple Store 4 美元 礼品卡",
    text:"兑换后充值到余额",
    link:"https://37apps.net/shop/1005"
  },{
    name:"美区 Apple Store 3 美元 礼品卡",
    text:"兑换后充值到余额",
    link:"https://37apps.net/shop/1004"
  },{
    name:"美区 Apple Store 2 美元 礼品卡",
    text:"兑换后充值到余额",
    link:"https://37apps.net/shop/1003"
  }];

  const links2 = [{
    name:"美区 AppleID",
    text:"购买链接",
  },{
    name:"美区 App",
    text:"购买链接",
    link:"http://appleshop001.club?from=1761"
  }];

  const links3 = [{
    name:"Netflix 会员",
    text:"购买链接",
    link:"http://appleshop001.club?from=1761"
  }];

  const all = [{
    name:"Huahua Store",
    text:"购买链接",
    link:"https://huahua.idaa.me/"
  },{
    name:"iMaor Store",
    text:"购买链接",
    link:"https://buy.maor.cc/"
  },{
    name:"Gakki Store",
    text:"购买链接",
    link:"https://www.gakkismile.icu/"
  },{
    name:"Rocket Store",
    text:"购买链接",
    link:"https://www.xiaohuojian8.com/"
  },{
    name:"HanHan Store",
    text:"购买链接",
    link:"https://fk.myue.cc/"
  }];

  const feature = [{
    name:"美区 AppleID",
    text:"购买链接",
    link:"https://37apps.net/shop/3001"
  }]

  return (
    <div className="App">


    <nav className="navbar navbar-expand-lg navbar-dark bg-dark" aria-label="Main navigation">
  <div className="container-fluid">
    <a className="navbar-brand" href="#"></a>

    <div className="navbar-collapse offcanvas-collapse" id="navbarsExampleDefault">
      <ul className="navbar-nav me-auto mb-2 mb-lg-0">
        <li className="nav-item">
          <a className="nav-link active" aria-current="page" href="#">AppleID</a>
        </li>
      </ul>
    </div>
  </div>
</nav>

<main className="container">

      <Modal
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <h2 ref={(_subtitle) => (subtitle = _subtitle)}>美区免税州</h2>
        <button onClick={closeModal}>close</button>
        <div>

    <p>美国的免税州有五个，分别是俄勒冈Oregon、阿拉斯加Alaska、特拉华州delaware、蒙大拿州Montana、新罕布什尔New Hampshire。所以只要把你的美区Apple ID的地址改成这五个州中的一个就可以了。</p>
    <p>美国 Apple ID 账号改为免税州方法</p>
    <p>1. 首先打开 https://appleid.apple.com/  点击 “管理你的Apple ID账户” 界面</p>
    <p>2. 接着点击个人信息，再点“国家或地区”，然后点击蓝色的“更改国家或地区”</p>
    <p>3. 进入付款方式页面，然后填写一个免税州的地址资料信息，这里以阿拉斯加州为例</p>
    <p>4. 填写的模板如下：</p>
    <p>国家或地区：美国</p>
<p>
选择付款方式：无
</p>

<p>
名字：San 姓氏：Zhang （姓名可以随便写，拼音字母就行）
</p>
<p>
街道地址：475 Woodbridge Lane （475数字可以随便写，可以不相同）
</p>
<p>
楼号、单元号、房间号：980682 （数字可以随便写，四位，五位，六位都行）
</p>
<p>
城市：Alaska
</p>
<p>
省/市：阿拉斯加州
</p>
<p>
邮编：99613 （不可以更改，必须99613）
</p>
<p>
电话号码：1321231152（此处可以不一样，随便写个手机号，必须是手机号，必须13开头，18开头，17开头这样的正常手机号，可以随便写，必须11位数字）
</p>
<p>
4.最后将页面拖至页面最底部，点击“更新”按钮就可以了，更新之后账号会自动定位到免税区域了
</p>

        </div>
      </Modal>

  <div className="my-3 p-3 bg-body rounded shadow-sm">
    <h6 className="border-bottom pb-2 mb-0">Featured</h6>
    {
    feature.map((link)=>{
      return (
        <div className="d-flex text-muted pt-3">
        <svg className="bd-placeholder-img flex-shrink-0 me-2 rounded" width="36" height="36" xmlns="http://www.w3.org/2000/svg" role="img" aria-label="Placeholder: 32x32" preserveAspectRatio="xMidYMid slice" focusable="false"><rect width="100%" height="100%" fill="#03ac13"/><text x="50%" y="50%" fill="#007bff" dy=".4em"></text></svg>

        <div className="pb-3 mb-0 small lh-sm border-bottom w-100">
        <div className="d-flex justify-content-between">
        <strong className="text-gray-dark">{link.name}</strong>
        </div>
        <span className="d-block">
          <a href={link.link}>{link.text}</a>
        </span>
        </div>
        </div>
      )
    })
    }
  </div>


  <div className="my-3 p-3 bg-body rounded shadow-sm">
    <h6 className="border-bottom pb-2 mb-0">37Apps Store</h6>
    {
    links.map((link)=>{
      return (
        <div className="d-flex text-muted pt-3">
        <svg className="bd-placeholder-img flex-shrink-0 me-2 rounded" width="36" height="36" xmlns="http://www.w3.org/2000/svg" role="img" aria-label="Placeholder: 32x32" preserveAspectRatio="xMidYMid slice" focusable="false"><title>Placeholder</title><rect width="100%" height="100%" fill="#007bff"/><text x="50%" y="50%" fill="#007bff" dy=".3em"></text></svg>

        <div className="pb-3 mb-0 small lh-sm border-bottom w-100">
        <div className="d-flex justify-content-between">
        <strong className="text-gray-dark">{link.name}</strong>
        </div>
        <span className="d-block">
          <a href={link.link} rel="noopener noreferrer">{link.text}</a>
        </span>
        </div>
        </div>
      )
    })
    }
        <div className="pb-3 mb-0 small lh-sm border-bottom w-100">
    <div className="d-flex text-muted pt-3">
      <p>在购买App时，如果提示余额不足，是由于税务问题，您可以选择</p>
    </div>
      <p>1. 充值2美元作为税务费用后购买。</p>
      <p style={{color:"red"}} onClick={openModal} >或者 2. 点击查看将 Apple ID 改为免税州帐户</p>
    </div>
  </div>

  {false?
  <div>
  <div className="my-3 p-3 bg-body rounded shadow-sm">
    <h6 className="border-bottom pb-2 mb-0">Apple Shop</h6>
    {
    links2.map((link)=>{
      return (
        <div className="d-flex text-muted pt-3">
        <svg className="bd-placeholder-img flex-shrink-0 me-2 rounded" width="36" height="36" xmlns="http://www.w3.org/2000/svg" role="img" aria-label="Placeholder: 32x32" preserveAspectRatio="xMidYMid slice" focusable="false"><rect width="100%" height="100%" fill="#FFC0CB"/><text x="50%" y="50%" fill="#007bff" dy=".4em"></text></svg>

        <div className="pb-3 mb-0 small lh-sm border-bottom w-100">
        <div className="d-flex justify-content-between">
        <strong className="text-gray-dark">{link.name}</strong>
        </div>
        <span className="d-block">
          <a href={link.link} rel="noopener noreferrer">{link.text}</a>
        </span>
        </div>
        </div>
      )
    })
    }
  </div>

  <div className="my-3 p-3 bg-body rounded shadow-sm">
    <h6 className="border-bottom pb-2 mb-0">Netflix</h6>
    {
    links3.map((link)=>{
      return (
        <div className="d-flex text-muted pt-3">
        <svg className="bd-placeholder-img flex-shrink-0 me-2 rounded" width="36" height="36" xmlns="http://www.w3.org/2000/svg" role="img" aria-label="Placeholder: 32x32" preserveAspectRatio="xMidYMid slice" focusable="false"><rect width="100%" height="100%" fill="#FFC0CB"/><text x="50%" y="50%" fill="#007bff" dy=".4em"></text></svg>

        <div className="pb-3 mb-0 small lh-sm border-bottom w-100">
        <div className="d-flex justify-content-between">
        <strong className="text-gray-dark">{link.name}</strong>
        </div>
        <span className="d-block">
          <a href={link.link} rel="noopener noreferrer">{link.text}</a>
        </span>
        </div>
        </div>
      )
    })
    }
  </div>

  </div>
    :null}

  <div className="my-3 p-3 bg-body rounded shadow-sm">
    <h6 className="border-bottom pb-2 mb-0">More Store</h6>
    {
    all.map((link)=>{
      return (
        <div className="d-flex text-muted pt-3">
        <svg className="bd-placeholder-img flex-shrink-0 me-2 rounded" width="36" height="36" xmlns="http://www.w3.org/2000/svg" role="img" aria-label="Placeholder: 32x32" preserveAspectRatio="xMidYMid slice" focusable="false"><rect width="100%" height="100%" fill="#999"/><text x="50%" y="50%" fill="#007bff" dy=".4em"></text></svg>

        <div className="pb-3 mb-0 small lh-sm border-bottom w-100">
        <div className="d-flex justify-content-between">
        <strong className="text-gray-dark">{link.name}</strong>
        </div>
        <span className="d-block">
          <a href={link.link} rel="noopener noreferrer">{link.text}</a>
        </span>
        </div>
        </div>
      )
    })
    }
  </div>

    </main>
    </div>
  );
}


export default App;
